<script lang="ts" setup>
import { listedTokenNav, preTgeNav, useNavState } from '#site/composables/nav'
import { createReusableTemplate, useMutationObserver, useWindowSize } from '@vueuse/core'

interface Options {
  title?: string
  icon?: string
  cta?: string
  contact?: boolean
  containerClass?: false | string
}

const props = withDefaults(defineProps<Options>(), { contact: true, containerClass: 'max-w-[1200px]' })
const runtimeConfig = useRuntimeConfig()

const options = computed<Options>(() => {
  return { ...props, ...useRoute().meta }
})

useHead({
  titleTemplate: '%s %separator Forgd',
  templateParams: {
    separator: '·',
  },
  title: options.value.title,
})

const [DefineFooter, ReuseFooter] = createReusableTemplate()
const teammateAccessWall = useTeammateAccessWall()
const auth = useAuth()
const router = useRouter()
const slots = useSlots()

const { project } = storeToRefs(auth)

const { data: navigation } = useAcademyNav()
const { data: files } = useLazyFetch<ParsedContent[]>('/api/search.json', { default: () => [], server: false })

const navSubItems = [
  ...preTgeNav.map(item => ({ ...item, preTge: true })),
  ...listedTokenNav.map(item => ({ ...item, preTge: false })),
]
  .flatMap(item => (item.children || []).map(child => ({ icon: item.icon, preTge: item.preTge, ...child })))
  .filter(item => item.to) as { to: string, label: string, icon?: string, preTge: boolean }[]

const tools = navSubItems
  .filter(item => item.to.startsWith('/') && !item.to.startsWith('/partner')) // relative links only, non-partner
  // we need to do a unique by for the .to as we'll have duplicates
  .filter((item, index, self) => self.findIndex(t => t.to === item.to) === index)

const _workbooks = navSubItems
  .filter(item => item.to.startsWith('https://docs.google.com'))
  .filter((item, index, self) => self.findIndex(t => t.to === item.to) === index)
const workbooks = _workbooks
  .map((item) => {
    item.icon = 'i-heroicons-document-chart-bar'
    const hasDuplicateLabel = _workbooks.filter(t => t.label === item.label).length > 1
    if (hasDuplicateLabel) {
      return ({ ...item, label: (item.preTge ? 'Pre-TGE: ' : 'Post-TGE: ') + item.label })
    }
    return item
  })

const thirdParty = navSubItems
  .filter(item => item.to.startsWith('/') && item.to.startsWith('/partner'))
  .filter((item, index, self) => self.findIndex(t => t.label === item.label) === index)

const groups = [
  {
    key: 'tools',
    label: 'In-House Tools',
    commands: tools,
  },
  {
    key: 'workbooks',
    label: 'Forgd Workbooks',
    commands: workbooks,
  },
  {
    key: 'third-party',
    label: '3rd Party Tools',
    commands: thirdParty,
  },
]

provide('navigation', navigation)
provide('files', files)

const { slideoverMenu, expanded } = useNavState()
const hasSidebarSlot = ref(false)
// make sure menu is closed between route changes
const route = useRoute()
watch(route, () => {
  slideoverMenu.value = null
}, {
  immediate: true,
})
const { width } = useWindowSize()
const shouldExpandMenuFromWidth = computed(() => width.value > 1460)
hasSidebarSlot.value = !!slots.sidebar
watch([shouldExpandMenuFromWidth, hasSidebarSlot], () => {
  if (hasSidebarSlot.value) {
    expanded.value = false
    return
  }
  expanded.value = shouldExpandMenuFromWidth.value
}, {
  immediate: true,
})

const sidebarContainer = useTemplateRef('sidebarContainer')
// we can't watch slots so we need to watch the DOM
// @ts-expect-error upstream type issue
useMutationObserver(sidebarContainer, () => {
  hasSidebarSlot.value = sidebarContainer.value?.children.length
}, {
  childList: true,
})

const shouldDisplayContactUsBannerInFooter = computed(() => {
  const availableRoutes = [
    'playbook',
    'token-settings',
    'token-designer',
    'active-dashboard',
    'market-maker-monitoring-start',
    'market-maker-monitoring-dashboard',
  ]
  return availableRoutes.includes(router.currentRoute.value.name)
})
</script>

<template>
  <DefineFooter>
    <slot v-if="shouldDisplayContactUsBannerInFooter" name="footer">
      <div v-if="options.contact" class="mx-auto border-t border-forgd-gray-300" :class="containerClass ? [containerClass, 'p-5'] : []">
        <!-- Footer call to action, something generic -->
        <AppContact class="!mt-0">
          <template #default>
            {{ options.cta || 'Contact us for a 1:1 consultation regarding all things related to your project.' }}
          </template>
          <template #cta>
            <UButton
              size="xl"
              color="black"
              class="px-12 py-3"
              :to="runtimeConfig.public.consultationUrl"
              target="_blank"
            >
              Schedule a 1:1 Consultation
            </UButton>
          </template>
        </AppContact>
      </div>
    </slot>
  </DefineFooter>
  <div data-layout="core/dashboard">
    <div
      class="bg-forgd-bgd-200 text-forgd-primary-900 min-h-screen font-normal font-display"
    >
      <div class="flex min-h-screen relative">
        <AppMenuExpandable />
        <main class="flex flex-col flex-grow" data-inertable>
          <!-- page header -->
          <div
            class="h-[80px] px-5 flex relative justify-between items-center border-b border-forgd-bgd-600"
          >
            <slot name="header">
              <div class="flex gap-2 items-center">
                <img v-if="options.icon && options.icon.endsWith('.svg')" :src="options.icon" class="h-[32px]">
                <div class="text-sm font-semibold flex-shrink-0">
                  {{ options.title }}
                </div>
              </div>
            </slot>

            <AppSearchButton class="absolute left-1/2 transform -translate-x-1/2" />

            <div class="flex-grow-0 flex items-center gap-3">
              <UiUserProjectsDropDown />
            </div>
          </div>

          <div class="min-h-[calc(100vh-80px)] flex w-full">
            <div ref="sidebarContainer">
              <slot v-if="$slots.sidebar" name="sidebar" />
            </div>
            <div class="flex-grow w-full">
              <div class="mx-auto flex-grow space-y-10" :class="containerClass ? [containerClass, 'p-5'] : []">
                <slot />
              </div>
              <ReuseFooter v-if="$slots.sidebar" />
            </div>
          </div>
          <ReuseFooter v-if="!$slots.sidebar" />
          <AppFooter />
        </main>
      </div>
    </div>
    <ClientOnly>
      <LazyAppSearch :files="files" :groups="groups" :navigation="navigation" />
    </ClientOnly>
    <UNotifications />
    <USlideovers />
    <DashboardTeammateAccessWall
      :show="teammateAccessWall.isVisible"
      :project="project"
      @close="teammateAccessWall.hide()"
    />
  </div>
</template>
